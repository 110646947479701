<template>
  <Container class="my-16 lg:my-24">
    <form class="relative text-right p-6 text-white lg:p-8 bg-gradient-black-01 rounded-3xl"
          @submit.prevent="submitForm"
    >
      <h2 class="text-4xl text-left text-white">
        {{ title }}
      </h2>
      <p v-if="copy && copy.length > 0"
         class="my-6 text-left"
      >
        {{ copy }}
      </p>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6 text-left">
        <SectionEmailFormInput :id="'first-name'"
                               v-model="inputs.firstName"
                               :state="state"
                               :label="'First Name'"
                               :input-type="'text'"
                               :required="true"
        />
        <SectionEmailFormInput :id="'last-name'"
                               v-model="inputs.lastName"
                               :state="state"
                               :label="'Last Name'"
                               :input-type="'text'"
                               :required="true"
        />
        <SectionEmailFormInput :id="'email'"
                               v-model="inputs.email"
                               :state="state"
                               :label="'E-mail'"
                               :input-type="'email'"
                               :required="true"
        />
        <SectionEmailFormInput :id="'company'"
                               v-model="inputs.company"
                               :state="state"
                               :label="'Company'"
                               :input-type="'text'"
                               :required="false"
        />
      </div>
      <div class="text-left">
        <label for="message"
               class="required"
        >Message</label>
        <textarea id="message"
                  v-model="inputs.message"
                  required
                  :disabled="state === 'loading'"
                  name="message"
                  class="w-full py-4 px-6 text-base font-medium text-white placeholder-gray-200 bg-gray-300 border-none rounded-2xl focus:outline-none focus:ring-blue-300 focus:ring-2 focus:ring-offset-black disabled:cursor-wait"
                  placeholder="Message"
                  :class="state === 'error' ? 'ring ring-red-200' : 'ring-0'"
        />
      </div>
      <div class="contact-terms flex justify-end py-6 space-x-4 text-sm font-medium text-gray-200 transition-opacity duration-100"
           v-html="privacyLabel"
      />
      <SectionEmailFormButton :state="state"
                              :label="buttonLabel"
      />
      <SectionEmailFormToast :state="state"
                             :success-message="successMessage"
                             :error-message="errorMessage"
      />
    </form>
  </Container>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import type { FormStateType } from '../../../types/form'

interface EmailFormProps {
  title: string
  emailTo: string
  emailSubject: string
  copy: string
  privacyLabel: string
  buttonLabel: string
  successMessage: string
  errorMessage: string
}

const props = defineProps<EmailFormProps>()

const inputs = ref({
  firstName: '',
  lastName: '',
  email: '',
  company: '',
  message: ''
})

const state = ref<FormStateType>('idle')

async function submitForm(event: Event) {
  event.preventDefault()

  state.value = 'loading'

  const formData = {
    to: props.emailTo,
    subject: props.emailSubject,
    firstName: inputs.value.firstName,
    lastName: inputs.value.lastName,
    email: inputs.value.email,
    company: inputs.value.company,
    message: inputs.value.message
  }

  await fetch('https://outfit7.com/send-transac-email', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(formData)
  })
    .then(async response => {
      if (response.ok) {
        state.value = 'success'
        inputs.value.firstName = ''
        inputs.value.lastName = ''
        inputs.value.email = ''
        inputs.value.company = ''
        inputs.value.message = ''
      }
      else {
        throw new Error(await response.json())
      }
    })
    .catch(error => {
      state.value = 'error'
      console.error(error)
    })
    .finally(() => {
      setTimeout(() => {
        state.value = 'idle'
      }, 3000)
    })
}
</script>

<style scoped lang="scss">
.required::after {
  content: ' *';
}

.contact-terms:deep(a) {
  @apply underline font-bold;
}
</style>
